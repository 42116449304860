@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&family=Montserrat:wght@400;700&family=Helvetica:wght@400;700&display=swap');

.ql-editor {
  font-family: 'Helvetica Neue', sans-serif;
}

.ql-toolbar.ql-snow {
  border: none;
  background-color: #fafafa;
  box-sizing: border-box;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  padding: 8px;
  border-radius: 10px 10px 0px 0px;
  /* margin-bottom: 10px; */
}

.ql-container.ql-snow {
  border: none;
  background-color: #fafafa;
  border-radius: 0px 0px 10px 10px;
  padding: 0px;
  font-size: 14px;
  min-height: 70px;
  margin-bottom: 10px;
  /* margin-left: 10px; */
}